import {callApi} from "./_axios";


/* AUTH */

export const signIn = async (email, password) => {
    const resp = await callApi('/auth/signin', 'post', {username: email, password});
    return resp.data;
};

export const signUp = async (userData) => {
    /*  userData

        country_code: "",
        city: "",
        state: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        password: "",
        password2: ""

     */

    const resp = await callApi('/auth/signup', 'post', userData);
    return resp.data;
};

export const resetPassword = async (passwordResetToken, new_password) => {
    const resp = await callApi('/auth/reset-password', 'post', {'token': passwordResetToken, new_password});
    return resp.data;
};

export const passwordForget = async (email) => {
    const resp = await callApi('/auth/password-forget', 'post', {email});
    return resp.data;
};

export const checkPasswordResetToken = async (passwordResetToken) => {
    const resp = await callApi('/auth/check-password-reset-token', 'post', {'token': passwordResetToken});
    return resp.data;
};

export const accountConfirmation = async (accountConfirmationToken) => {
    const resp = await callApi('/auth/confirm-account', 'post', {'token': accountConfirmationToken});
    return resp.data;
};

export const resendAccountConfirmationLink = async (user_id) => {
    const resp = await callApi('/auth/resend-account-confirmation-link', 'post', {user_id});
    return resp.data;
};

