import React, {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';
import Banner from '../../assets/img/ic_login.png';
import {signUp as apiSignUp, resendAccountConfirmationLink as apiResendAccountConfirmationLink} from '../../api/auth';
import {getCountry as apiGetCountry} from '../../api/common';
import {useSwal} from '../../hooks/swal';
import * as RoutesPath from "../../constants/pages";

const SignUpPage = (props) => {

    const swal = useSwal();

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        country_code: "",
        city: "",
        state: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        phone_number_prefix: "", // not send to api (because it can be deducted from selected country)
        password: "",
        password2: ""
    });

    const [countryData, setCountryData] = useState([]);

    const [formError, setFormError] = useState(null);

    const [signUpStatus, setSignUpStatus] = useState({
        is_success: false,
        user_id: null
    });

    const [resendClickCount, setResendClickCount] = useState(0);

    const handleFormSubmit = (e) => {

        e.preventDefault();

        setFormError(null);

        if(!formData.country_code){
            setFormError("Selectionner le pays");
            window.scrollTo(0, 1);
            return;
        }

        if(!formData.city){
            setFormError("Entrer la ville");
            window.scrollTo(0, 1);
            return;
        }

        if(!formData.first_name){
            setFormError("Entrer le prenom");
            window.scrollTo(0, 1);
            return;
        }

        if(!formData.last_name){
            setFormError("Entrer le nom");
            window.scrollTo(0, 1);
            return;
        }

        if(!formData.email){
            setFormError("Entrer l'adresse email");
            window.scrollTo(0, 1);
            return;
        }

        if(!formData.phone_number_prefix){
            setFormError("Selectionner le prefix du contcat");
            window.scrollTo(0, 1);
            return;
        }

        if(!formData.phone_number){
            setFormError("Enter le contact");
            window.scrollTo(0, 1);
            return;
        }

        if(formData.password.length < 8){
            console.log(formData.password.length)
            setFormError("Le mot de passe doit avoit au moin 8 caractères");
            window.scrollTo(0, 1);
            return;
        }

        if(!formData.password || !formData.password2){
            setFormError("Entrer le mot de passe");
            window.scrollTo(0, 1);
            return;
        }

        if(formData.password !== formData.password2){
            setFormError("Mot de passe non identique");
            window.scrollTo(0, 1);
            return;
        }

        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {});
        swal.showLoading();

        apiSignUp(formData)
            .then((data) => {
                swal.fire({
                    icon: 'success',
                    title: '',
                    text: "La creation de votre compte à été effectuée avec succès. Un email de confirmation de compte à été envoyé"
                }).then(() => {
                    setSignUpStatus({
                        is_success: true,
                        user_id: data.user_id
                    })
                });
            })
            .catch((error) => {

                if (error.response){
                    if (error.response.data.message){

                        setFormError(error.response.data.message);

                        swal.fire({
                            icon: 'error',
                            title: 'Erreur !!',
                            text: error.response.data.message
                        }).then(() => {});

                        window.scrollTo(0, 1);

                        return;
                    }
                }

                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: 'Impossible de traiter la requête'
                }).then(() => {});

            })
            .then(() => {
                setFormData(state => ({...state, password: "", password2: ""}));
                swal.hideLoading();
            })
    };

    const handleFormFieldChange = (e) => {
        setFormData(state => ({...state, [e.target.name]: e.target.value}));

        if (e.target.name === "country_code"){

            if (!e.target.value){
                return;
            }

            const country = countryData.find(item => (item.code === e.target.value));

            setFormData(state => ({...state, 'phone_number_prefix': country.dial_code}));
        }
    };

    const resentConfirmationEmail = (e) => {

        e.preventDefault();

        setResendClickCount((state) => state + 1);

        if(resendClickCount === 2){
            console.log("max resend comfirmation link")
            navigate(RoutesPath.AUTH_SIGN_IN_PAGE.path);
            return;
        }

        if (!signUpStatus.is_success || !signUpStatus.user_id){
            console.error("")
            return;
        }

        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {})
        swal.showLoading();

        apiResendAccountConfirmationLink(signUpStatus.user_id)
            .then((data) => {
                swal.fire({
                    icon: 'success',
                    title: '',
                    text: "Un email de confirmation de compte à été renvoyé"
                }).then(() => {});
            })
            .catch((error) => {

                if (error.response){
                    if (error.response.data.message){
                        swal.fire({
                            icon: 'error',
                            title: 'Erreur !!',
                            text: error.response.data.message
                        }).then(() => {});
                    }
                }

                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: 'Impossible de traiter la requête'
                }).then(() => {});

            })
            .then(() => {
                swal.hideLoading();
            })
    }

    useEffect(() => {
        window.scrollTo(0, 1);

        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {})
        swal.showLoading();

        apiGetCountry()
            .then((data) => {
                setCountryData(data);
                swal.hideLoading();
                swal.close();
            })
            .catch(err => {
                swal.hideLoading();
                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: "Une erreur s'est produite"
                }).then(() => {
                    navigate(-1);
                });
            });

    }, []);



    if(!signUpStatus.is_success){

        return (
            <>
                <section className="inner-banner">
                    <div className="container">
                    </div>
                </section>


                <section className="signin py-5">
                    <div className="container">
                        <div className="row main-content-agile align-items-center">
                            <div className="col-md-7">
                                <div className="sub-main-w3 text-center">
                                    <h3>Création du Compte</h3>
                                    <p className="mt-2 mb-4">Entrer les détails de votre compte.</p>

                                    {
                                        formError !== null && (<div className="alert alert-danger" role="alert">{formError}</div>)
                                    }

                                    <form onSubmit={handleFormSubmit}>
                                        <div className="icon1">
                                            <select className="form-control form-control-md" onChange={handleFormFieldChange} value={formData.country_code} placeholder="Pays" name="country_code" >
                                                <option value="">Selectionner le Pays</option>
                                                {countryData.map(item => (<option key={item.code} value={item.code}>{item.name}</option>))}
                                            </select>
                                        </div>
                                        <div className="icon1">
                                            <input onChange={handleFormFieldChange} value={formData.city} placeholder="Ville" name="city" type="text" required />
                                        </div>
                                        <div className="icon1">
                                            <input onChange={handleFormFieldChange} value={formData.email} placeholder="Email" name="email" type="email" required />
                                        </div>
                                        <div className="icon1">
                                            <input onChange={handleFormFieldChange} value={formData.last_name} placeholder="Nom" name="last_name" type="text" required />
                                        </div>
                                        <div className="icon1">
                                            <input onChange={handleFormFieldChange} value={formData.first_name} placeholder="Prenom" name="first_name" type="text" required />
                                        </div>
                                        <div className="icon2">
                                            <input value={formData.phone_number_prefix} readOnly={true} placeholder="Prefix telephonique" name="phone_number_prefix" type="text" required />
                                        </div>
                                        <div className="icon2">
                                            <input onChange={handleFormFieldChange} value={formData.phone_number} placeholder="Contact" name="phone_number" type="text" required />
                                        </div>
                                        <div className="icon2">
                                            <input onChange={handleFormFieldChange} value={formData.password} placeholder="Password" name="password" type="password" required />
                                        </div>
                                        <div className="icon2">
                                            <input onChange={handleFormFieldChange} value={formData.password2} placeholder="Confirmer le mot de passe" name="password2" type="password" required />
                                        </div>
                                        <input type="submit" value="Créer un Compte" />
                                        <p>
                                            Déja un compte ?
                                            <Link to={RoutesPath.AUTH_SIGN_IN_PAGE.path} className="ml-2"><strong>connectez vous a votre compte</strong></Link>
                                        </p>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-5 text-center">
                                <img src={Banner} width="80%" alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }

    return (
        <>
            <section className="inner-banner">
                <div className="container">
                </div>
            </section>

            <section className="about-us py-5">
                <div className="container py-md-3">
                    <div className="heading mb-5">
                        <h3 className="head text-center">
                            Bonjour {formData.last_name} {formData.first_name}
                        </h3>

                        <p className="my-3 head text-center text-m">
                            Merci pour votre inscription ! <br />
                            vous recevrez un lien sur votre adresse email {formData.email} pour la confirmation de votre email.

                            <br />
                            <br />

                            <a className="pp_button" href={RoutesPath.AUTH_SIGN_IN_PAGE.path}>Connexion</a>
                        </p>

                        <br />

                        <p className="my-3 head text-center">
                            vous n'avez pas reçu de mail ? <a style={{color: "black"}} href="#" onClick={resentConfirmationEmail}>cliquez ici</a>
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SignUpPage;
