import React, {useEffect, useRef, useState} from "react";
import * as apiUser from "../../../api/user";
import {useSwal} from "../../../hooks/swal";
import {useNavigate} from 'react-router-dom';
import $ from 'jquery';

const ProfilePage = (props) => {

    const [userData, setUserData] = useState(null);
    const [formUserAddress, setFormUserAddress] = useState("");
    const [formUserZipCode, setFormUserZipCode] = useState("");
    const [formUserRibBancaire, setFormUserRibBancaire] = useState("");
    const [formPasswordError, setFormPasswordError] = useState("");
    const [formPasswordData, setFormPasswordData] = useState({
        old_password: "",
        new_password: "",
        new_password2: ""
    });
    const swal = useSwal();
    const navigate = useNavigate();

    const passwordModalRef = useRef(null);

    const handleAddressChange = (e) => {
        setFormUserAddress(e.target.value);
    };

    const handleZipCodeChange = (e) => {
        setFormUserZipCode(e.target.value);
    };

    const handleRibBancaireChange = (e) => {
        setFormUserRibBancaire(e.target.value);
    };

    const handleFormProfileSubmit = (e) => {

        e.preventDefault();

        if(!formUserAddress){

            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Entrer votre l'adresse "
            }).then(() => {});

            window.scrollTo(0, 1);
            return;
        }

        if(formUserZipCode.length !== 5){

            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Entrer le zip code de 5 caractères"
            }).then(() => {});

            window.scrollTo(0, 1);
            return;
        }

        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {})
        swal.showLoading();

        apiUser.updateUserProfile(formUserAddress, formUserZipCode, formUserRibBancaire)
            .then(data => {

                swal.fire({
                    icon: 'success',
                    title: '',
                    text: "La modification à été effectuée"
                }).then(() => {});

            })
            .catch(err => {
                swal.hideLoading();
                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: "Impossible de traiter la requête"
                }).then(() => {});

            })
            .then(() => {
                window.scrollTo(0, 1);
            });
    };

    const handleFormPasswordSubmit = (e) => {

        e.preventDefault();

        if(!formPasswordData.old_password){
            setFormPasswordError("Entrez l'ancien mot de passe");
            return;
        }

        if(!formPasswordData.new_password || !formPasswordData.new_password2){
            setFormPasswordError("Entrez le nouveau mot de passe");
            return;
        }

        if (formPasswordData.new_password < 8){
            setFormPasswordError("Le mot de passe doit avoir au minimun 8 caractères");
            return;
        }

        if(formPasswordData.new_password !== formPasswordData.new_password2){
            setFormPasswordError("Mot de passe non identique");
            return;
        }


        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {})
        swal.showLoading();

        apiUser.updateUserPassword(formPasswordData.old_password, formPasswordData.new_password)
            .then(data => {

                handleFormPasswordModalClose();
                swal.hideLoading()
                swal.fire({
                    icon: 'success',
                    title: '',
                    text: "La modification à été effectuée"
                }).then(() => {});

            })
            .catch(err => {
                swal.hideLoading();
                swal.close();

                if (err.response){
                    setFormPasswordError(err.response.data.message);
                }
                else {
                    setFormPasswordError("Impossible de traiter la requête");
                }
            })
            .then(() => {});
    };

    const handleFormPasswordModalClose = () => {
        setFormPasswordData({
            old_password: "",
            new_password: "",
            new_password2: ""
        });

        setFormPasswordError("");

        $(passwordModalRef.current).modal("hide");
    };

    const handleFormPasswordFieldChange = (e) => {

        if (e.target.name === "old_password"){
            setFormPasswordData((state) => ({...state, old_password: e.target.value}));
        }
        else if(e.target.name === "new_password"){
            setFormPasswordData((state) => ({...state, new_password: e.target.value}));
        }
        else if(e.target.name === "new_password2"){
            setFormPasswordData((state) => ({...state, new_password2: e.target.value}));
        }
        else {
        }

    };

    useEffect(() => {

        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {})
        swal.showLoading();

        apiUser.getLoginUser()
            .then(data => {
                setUserData(data);
                setFormUserAddress(data.address);
                setFormUserZipCode(data.zip_code);
                setFormUserRibBancaire(data.bank_rib);
                swal.hideLoading();
                swal.close();
            })
            .catch(err => {
                swal.hideLoading();
                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: "Une erreur s'est produite"
                }).then(() => {
                    navigate(-1);
                });

            });

        window.scrollTo(0, 1);
    }, []);


    let hasFormChange = false;
    if (userData){
        if((formUserAddress !== userData.address) || (formUserZipCode !== userData.zip_code) || (formUserRibBancaire !== userData.bank_rib)){
            hasFormChange = true;
        }
    }

    return (
        <>
            <section className="inner-banner">
                <div className="container">
                </div>
            </section>

            <section hidden={!userData} className="contact py-5">
                <div className="container py-md-3">
                    <div className="heading">
                        <h3 className="head text-center">Informations sur votre personnel</h3>
                        <p className="text-center mt-3">
                            <a 
                                className="btn btn-secondary btn-sm"
                                target="_blank"
                                href={`${process.env.REACT_APP_BACKEND_URL}/reports/customer/${userData?._id}`}
                            >
                                Fiche
                            </a>
                            <br />
                            <a 
                                className="btn btn-secondary btn-sm mt-2" 
                                target="_blank"
                                href={`${process.env.REACT_APP_BACKEND_URL}/reports/customer/${userData?._id}/_download`}
                            >
                                Télécharger Fiche
                            </a>
                            <br />
                            <button className="btn btn-secondary btn-md ml-2 mt-2 " data-toggle="modal" data-target="#passwordModal">Changer de Mot de Passe</button>
                        </p>
                    </div>
                    <div className="contact-form mt-4">
                        <div className="row">
                            <div className="col-lg-7 col-md-10 mx-auto">
                                <form onSubmit={handleFormProfileSubmit} action="">
                                    <div className="form-group">
                                        <label>Matricule</label>
                                        <input readOnly value={userData?.matricule} type="text" className="form-control" name="last_name" />
                                    </div>
                                    <div className="form-group">
                                        <label>Nom</label>
                                        <input readOnly value={userData?.last_name} type="text" className="form-control" name="last_name" />
                                    </div>
                                    <div className="form-group">
                                        <label>Prenom</label>
                                        <input readOnly value={userData?.first_name} type="text" className="form-control" name="first_name" />
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input readOnly value={userData?.email} type="email" className="form-control" name="email" />
                                    </div>

                                    <div className="form-group">
                                        <label>Contact</label>
                                        <input readOnly value={`${userData?.phone_number_prefix} ${userData?.phone_number}`} type="text" className="form-control" placeholder="" name="phone" />
                                    </div>

                                    <div className="form-group">
                                        <label>Adresse</label>
                                        <input value={formUserAddress} onChange={handleAddressChange} type="text" className="form-control" name="address" />
                                    </div>

                                    <div className="form-group">
                                        <label>Zip Code</label>
                                        <input value={formUserZipCode} onChange={handleZipCodeChange} type="text" className="form-control" name="zip_code" />
                                    </div>

                                    <div className="form-group">
                                        <label>RIB Bancaire</label>
                                        <input value={formUserRibBancaire} onChange={handleRibBancaireChange} type="text" className="form-control" name="rib_bank" />
                                    </div>

                                    <p className="text-center">
                                        <button disabled={!hasFormChange} type="submit" className="btn btn-default">Modifier</button>
                                    </p>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div ref={passwordModalRef} className="modal fade" id="passwordModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Changement de Mot de Passe</h5>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div hidden={!formPasswordError} className="alert alert-danger" role="alert">
                                    {formPasswordError}
                                </div>

                                <div className="form-group">
                                    <label>Ancien Mot de passe</label>
                                    <input onChange={handleFormPasswordFieldChange} value={formPasswordData.old_password} type="password" className="form-control" name="old_password" />
                                </div>
                                <div className="form-group">
                                    <label>Nouveau Mot de passe</label>
                                    <input onChange={handleFormPasswordFieldChange} value={formPasswordData.new_password} type="password" className="form-control" name="new_password" />
                                </div>
                                <div className="form-group">
                                    <label>Confirmer le Mot de passe</label>
                                    <input onChange={handleFormPasswordFieldChange} value={formPasswordData.new_password2} type="password" className="form-control" name="new_password2" />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button onClick={handleFormPasswordModalClose} type="button" className="btn btn-secondary">Annuler</button>
                            <button onClick={handleFormPasswordSubmit} type="button" className="btn btn-primary">Confirmer</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProfilePage;
