import {callApi} from "./_axios";

/* PAYMENT */

export const newSubscriptionPaymentLinkGeneration = async (taxi_offer_id, echeance_number, address, zip_code) => {
    const resp = await callApi('/payment/new-subscription-payment-link-generation', 'post', {taxi_offer_id: taxi_offer_id, echeance_number, address, zip_code});
    return resp.data;
};

export const echeancePaymentLinkGeneration = async (subscription_id) => {
    const resp = await callApi('/payment/echeance-payment-link-generation', 'post', {subscription_id});
    return resp.data;
};

