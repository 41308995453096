import React, {useEffect, useState} from "react";
import Banner from '../../assets/img/banner.png';
import {accountConfirmation as apiAccountConfirmation} from "../../api/auth";
import {useSearchParams, useNavigate, Navigate, Link} from 'react-router-dom';
import {useSwal} from '../../hooks/swal';
import * as RoutesPath from '../../constants/pages';


const AccountConfirmationPage = (props) => {
    const swal = useSwal();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const accountConfirmationToken = searchParams.get('token');
    const [userInfo, setUserInfo] = useState({
        first_name: "",
        last_name: ""
    });
    const [isInvalidToken, setIsInvalidToken] = useState(false);
    const [isTokenChecked, setIsTokenChecked] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 1);

        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {})
        swal.showLoading();

        // check if token is valid or active
        apiAccountConfirmation(accountConfirmationToken)
            .then((data) => {
                setUserInfo({
                    first_name: data.user.first_name,
                    last_name: data.user.last_name
                });
            })
            .catch((error) => {

                if (error.response){
                    console.log(error.response);
                    if (error.response.data.message){
                        swal.fire({
                            icon: 'error',
                            title: 'Erreur !!',
                            text: error.response.data.message
                        }).then(() => {});
                    }
                }

                setIsInvalidToken(true);
            })
            .then(() => {
                swal.close();
                swal.hideLoading();
                setIsTokenChecked(true);
            })

    }, []);

    if (!accountConfirmationToken){
        setIsInvalidToken(true)
    }

    if(!isTokenChecked){

        return (
            <></>
        );
    }

    if (isInvalidToken){
        return (
            <>
                <section className="inner-banner">
                    <div className="container">
                    </div>
                </section>

                <section className="about-us py-5">
                    <div className="container py-md-3">
                        <div className="heading mb-5">
                            <h3 className="head text-center">
                            </h3>

                            <p className="my-3 head text-center text-m">
                                le lien à expirer ou est invalide
                            </p>

                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </section>
            </>
        );
    }

    return (
        <>
            <section className="inner-banner">
                <div className="container"></div>
            </section>


            <section className="about-us py-5">
                <div className="container py-md-3">
                    <div className="heading mb-5">
                        <h3 className="head text-center">
                            Bonjour {userInfo.last_name} {userInfo.first_name}
                        </h3>

                        <br /><br />

                        <p className="my-3 head text-center text-m">
                            Merci pour votre inscription !

                            <br /><br />

                            Votre compte à été activé.
                            Cliquer sur le lien ci-dessous pour vous connecter
                            <br />
                            <br />

                            <a className="pp_button" href={RoutesPath.AUTH_SIGN_IN_PAGE.path}>Connexion</a>
                        </p>

                        <br />
                        <br />
                        <br />

                    </div>
                </div>
            </section>

        </>
    );
}

export default AccountConfirmationPage;
