import React from "react";

const Footer = (props) => {

    return (
        <>
            <section className="copy-right bg-light py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-9">
                            <p className="">© 2022 Work. All rights reserved | Design by
                                <a href="#"> PPSOFTCI.</a> |
                                <a className="ml-2" href="https://montaxi-admin.al-iklassmulti-service.net/auth/login"> @</a>
                            </p>
                        </div>
                        <div className="col-lg-5 col-md-3">
                            <ul className="social-iconsv2 agileinfo d-flex">
                                <li>
                                    <a>
                                        <i className="fab fa-facebook-square"></i>
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <i className="fab fa-twitter-square"></i>
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <i className="fab fa-google-plus-square"></i>
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <i className="fab fa-linkedin"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Footer;
