import React from "react";
import img404 from '../../assets/img/404.png';

const ErrorPage = (props) => {

    let errorTitle = "";
    let errorMsg = "";

    return (
        <>
            <h3 style={{textAlign: "center"}}>{errorMsg}</h3>

            <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                <img width="100%" src={img404} alt="" />
            </div>
        </>
    );
}

export default ErrorPage;
