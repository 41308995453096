import React from "react";
import {Routes, Route, Navigate} from 'react-router-dom';
import AppLayout from "./pages/_AppLayout";
import AuthPagesMdl from "./pages/_AuthPagesMdl";
import MemberSpacePagesMdl from "./pages/_MemberSpacePagesMdl";
import ErrorPage from "./pages/ErrorPage";
import HomePage  from "./pages/HomePage";
import SubscriptionPage from "./pages/backoffice/SubscriptionPage";
import SubscriptionDetailPage from "./pages/backoffice/SubscriptionDetailPage";
import SubscriptionEcheancePaymentPage from "./pages/backoffice/SubscriptionEcheancePaymentPage";
import NewSubscriptionPage from "./pages/backoffice/NewSubscriptionPage";
import ProfilePage from "./pages/backoffice/ProfilePage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import ContactPage from "./pages/ContactPage";
import FaqPage from "./pages/FaqPage";
import PasswordForgetPage from "./pages/PasswordForgetPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import AccountConfirmationPage from "./pages/AccountConfirmationPage";
import * as RoutesPath from './constants/pages';


function App() {
  return (
      <Routes>
          <Route path="/" element={<AppLayout />}>

              {/* */}
              <Route index element={<HomePage />}></Route>
              <Route path={RoutesPath.FAQ_PAGE.path} element={<FaqPage />}></Route>
              <Route path={RoutesPath.CONTACT_PAGE.path} element={<ContactPage />}></Route>

              {/* Auth Pages */}
              <Route path={RoutesPath.AUTH_PAGES_PREFIX} element={<AuthPagesMdl />}>

                  {/* */}

                  {/* redirect for unknown sub route */}
                  <Route index element={(<ErrorPage />)}></Route>

                  <Route path={RoutesPath.AUTH_SIGN_IN_PAGE.subpath} element={<SignInPage style={{}} />}></Route>
                  <Route path={RoutesPath.AUTH_SIGN_UP_PAGE.subpath} element={<SignUpPage />}></Route>
                  <Route path={RoutesPath.AUTH_PASSWORD_FORGET_PAGE.subpath} element={<PasswordForgetPage />}></Route>
                  <Route path={RoutesPath.AUTH_PASSWORD_RESET_PAGE.subpath} element={<PasswordResetPage />}></Route>
                  <Route path={RoutesPath.AUTH_ACCOUNT_CONFIRMATION_PAGE.subpath} element={<AccountConfirmationPage />}></Route>

              </Route>

              {/* protected routed (user area) */}
              <Route path={RoutesPath.MEMBER_SPACE_PAGES_PREFIX} element={<MemberSpacePagesMdl />}>

                  {/* redirect for unknown sub route */}
                  <Route index element={(<ErrorPage />)}></Route>

                  <Route path={RoutesPath.MS_SUBSCRIPTION_PAGE.subpath} element={<SubscriptionPage />}></Route>
                  <Route path={`${RoutesPath.MS_SUBSCRIPTION_DETAIL_PAGE.subpath}/:id`} element={<SubscriptionDetailPage />}></Route>
                  <Route path={`${RoutesPath.MS_SUBSCRIPTION_ECHEANCE_PAYMENT_PAGE.subpath}/:id`} element={<SubscriptionEcheancePaymentPage />}></Route>
                  <Route path={RoutesPath.MS_NEW_SUBSCRIPTION_PAGE.subpath} element={<NewSubscriptionPage />}></Route>
                  <Route path={RoutesPath.MS_PROFILE_PAGE.subpath} element={<ProfilePage />}></Route>
              </Route>

          </Route>

          {/**/}
          <Route path="*" element={<ErrorPage />}></Route>
      </Routes>
  );
}

export default App;
