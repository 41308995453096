import React, {useEffect} from "react";
import { Outlet, Link, NavLink } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const AppLayout = (props) => {

    return (
        <>
            <NavBar />

            <Outlet />

            <Footer />
        </>
    );
}

export default AppLayout;
