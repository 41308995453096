
/* */

export const HOME_PAGE = {path: "/", prefix: "", subpath: ""};

export const FAQ_PAGE = {path: "/faq", prefix: "", subpath: ""};

export const CONTACT_PAGE = {path: "/contact", prefix: "", subpath: ""};


/* */

/* AUTH */

export const AUTH_PAGES_PREFIX = "auth";

export const AUTH_SIGN_IN_PAGE = {path: `/${AUTH_PAGES_PREFIX}/signin`, prefix: AUTH_PAGES_PREFIX, subpath: 'signin'};

export const AUTH_SIGN_UP_PAGE = {path: `/${AUTH_PAGES_PREFIX}/signup`, prefix: AUTH_PAGES_PREFIX, subpath: 'signup'};

export const AUTH_PASSWORD_FORGET_PAGE = {path: `/${AUTH_PAGES_PREFIX}/password-forget`, prefix: AUTH_PAGES_PREFIX, subpath: 'password-forget'};

export const AUTH_PASSWORD_RESET_PAGE = {path: `/${AUTH_PAGES_PREFIX}/password-reset`, prefix: AUTH_PAGES_PREFIX, subpath: 'password-reset'};

export const AUTH_ACCOUNT_CONFIRMATION_PAGE = {path: `/${AUTH_PAGES_PREFIX}/account-confirmation`, prefix: AUTH_PAGES_PREFIX, subpath: 'account-confirmation'};

/*  AUTH */

/* Member Space */

export const MEMBER_SPACE_PAGES_PREFIX = "myspace";


export const MS_SUBSCRIPTION_PAGE = {path: `/${MEMBER_SPACE_PAGES_PREFIX}/subscription`, prefix: AUTH_PAGES_PREFIX, subpath: 'subscription'};

export const MS_NEW_SUBSCRIPTION_PAGE = {path: `/${MEMBER_SPACE_PAGES_PREFIX}/new-subscription`, prefix: AUTH_PAGES_PREFIX, subpath: 'new-subscription'};

export const MS_SUBSCRIPTION_DETAIL_PAGE = {path: `/${MEMBER_SPACE_PAGES_PREFIX}/subscription-detail`, prefix: AUTH_PAGES_PREFIX, subpath: 'subscription-detail'};

export const MS_SUBSCRIPTION_ECHEANCE_PAYMENT_PAGE = {path: `/${MEMBER_SPACE_PAGES_PREFIX}/subscription-echeance-payment`, prefix: AUTH_PAGES_PREFIX, subpath: 'subscription-echeance-payment'};

export const MS_PROFILE_PAGE = {path: `/${MEMBER_SPACE_PAGES_PREFIX}/profile`, prefix: AUTH_PAGES_PREFIX, subpath: 'profile'};

/* Member Space */
