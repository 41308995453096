import React from "react";
import {useSelector} from "react-redux";
import {Navigate, Outlet} from "react-router-dom";
import * as RoutesPath from "../constants/pages";

const MemberSpacePagesMdl = (props) => {

    const auth = useSelector(({auth}) => auth);

    // redirect user if not login
    if (!auth?.isAuthenticated){
        return <Navigate
            to={RoutesPath.AUTH_SIGN_IN_PAGE.path}
            replace={true}
            state={{authRedirectMsg: "Merci de vous connecter afin d'acceder à l'espace Membre"}}
        />;
    }

    return (<Outlet />);
}

export default MemberSpacePagesMdl;
