import {callApi} from "./_axios";

export const getUserCustomerSubscriptionsOverview = async () => {
    const resp = await callApi('/subscription', 'get', {});
    return resp.data;
};

export const getDetailedUserCustomerSubscription = async (subscriptionId) => {
    const resp = await callApi(`/subscription/${subscriptionId}`, 'get', {});
    return resp.data;
};
