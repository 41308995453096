import React, {useEffect, useState} from "react";
import Banner from '../../assets/img/ic_login.png';
import {signIn as apiSignIn} from "../../api/auth";
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {useSwal} from '../../hooks/swal';
import {signIn as signInAction} from "../../features/auth";
import * as RoutesPath from '../../constants/pages';


const SignInPage = (props) => {
    const swal = useSwal();
    const dispatch = useDispatch();
    const [formError, setFormError] = useState(null);
    const [emailField, setEmailField] = useState("");
    const [passwordField, setPasswordField] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    const handleFormSubmit = (e) => {
        e.preventDefault();

        setFormError(null);

        if(!emailField){
            setFormError("Enter votre adresse email");
            window.scrollTo(0, 1);
            return;
        }

        if(!passwordField){
            setFormError("Entrer le mot de passe");
            window.scrollTo(0, 1);
            return;
        }

        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {})
        swal.showLoading();

        apiSignIn(emailField, passwordField)
            .then((data) => {
                dispatch(signInAction({token: data.token, user: data.user}));
                navigate(RoutesPath.MS_SUBSCRIPTION_PAGE.path, {replace: true});
                swal.close();
            })
            .catch((error) => {

                if (error.response){
                    if (error.response.data.message){
                        setFormError(error.response.data.message);
                        swal.close();
                        return;
                    }
                }

                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: 'Impossible de traiter la requête'
                }).then(() => {});


            })
            .then(() => {
                setPasswordField("");
                swal.hideLoading();
            })
    };

    useEffect(() => {
        window.scrollTo(0, 1);
    }, []);


    return (
        <>
            <section className="inner-banner">
                <div className="container">
                    {location.state?.authRedirectMsg && <div className="alert alert-danger" role="alert">{location.state.authRedirectMsg}</div>}
                </div>
            </section>

            <section className="signin py-5">
                <div className="container">
                    <div className="row main-content-agile align-items-center">
                        <div className="col-lg-6 col-md-9 mx-auto">
                            <div className="sub-main-w3 text-center">
                                <h3>Connexion à votre Compte</h3>

                                <p className="mt-2 mb-4">Entrer votre email et le mot de passe pour vous connecter</p>

                                {
                                    formError !== null && (<div className="alert alert-danger" role="alert">{formError}</div>)
                                }

                                <form onSubmit={handleFormSubmit}>
                                    <div className="icon1">
                                        <input onChange={(e) => setEmailField(e.target.value)} value={emailField} placeholder="Email" name="mail" type="email" />
                                    </div>
                                    <div className="icon2">
                                        <input onChange={(e) => setPasswordField(e.target.value)} value={passwordField} placeholder="Mot de Passe" name="Password" type="password"  />
                                    </div>
                                    <label className="anim">
                                        <input type="checkbox" className="checkbox" style={{display:'none'}} />
                                        <span style={{display:'none'}}>Remember Me</span>
                                        <Link to={RoutesPath.AUTH_PASSWORD_FORGET_PAGE.path}>Mot de passe oublié ?</Link>
                                    </label>
                                    <div className="clearfix"></div>
                                    <input type="submit" value="Connexion" />
                                    <p>Pas de compte ? <Link to={RoutesPath.AUTH_SIGN_UP_PAGE.path} className="ml-2"><strong>Créer un compte</strong></Link></p>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8 mx-auto text-center">
                            <img src={Banner} width="70%" alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default SignInPage;
