import React, {useEffect} from "react";

const ContactPage = (props) => {

    useEffect(() => {
        window.scrollTo(0, 1);
    }, []);

    return (
        <>
            <section className="inner-banner">
                <div className="container">
                </div>
            </section>

            <section className="contact py-5">
                <div className="container py-md-3">
                    <div className="heading">
                        <h3 className="head text-center">Nous Contacter </h3>

                        <p className="my-3 head text-center border rounded p-4">
                            Email: Iklassbureau@gmail.com <br/>
                            Contact: +225 07 78 05 91 45 / +225 05 04 08 44 88 <br />
                            ABIDJAN COCODY STAR 10
                        </p>
                    </div>
                    <div className="contact-form mt-5">
                        <div className="row">
                            <div className="col-lg-7 col-md-10 mx-auto">
                                <form name="contactform" id="contactform" action="#">
                                    <div className="form-group">
                                        <label>Nom</label>
                                        <input type="text" className="form-control" id="name" placeholder="Entrer le nom" name="name" />
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" className="form-control" id="email" placeholder="Enter l'email" name="email" />
                                    </div>
                                    <div className="form-group">
                                        <label>Contact</label>
                                        <input type="text" className="form-control" id="phone" placeholder="Entrer le Contact" name="phone" />
                                    </div>
                                    <div className="form-group">
                                        <label>Objet</label>
                                        <input type="text" className="form-control" id="subject" placeholder="Objet" name="subject" />
                                    </div>
                                    <div className="form-group">
                                        <label>Comment pouvons nous vous aider ?</label>
                                        <textarea name="issues" className="form-control" id="iq" placeholder="Enter votre message"></textarea>
                                    </div>
                                    <p className="text-center">
                                        <button type="submit" className="btn btn-default">Envoyer</button>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="agileits-w3layouts-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.9503398796587!2d-73.9940307!3d40.719109700000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a27e2f24131%3A0x64ffc98d24069f02!2sCANADA!5e0!3m2!1sen!2sin!4v1441710758555" allowfullscreen=""></iframe>
            </section>
        </>
    );
}

export default ContactPage;
