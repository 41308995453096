import React, {useEffect, useState} from "react";
import Banner from '../../assets/img/banner.png';
import {passwordForget as apiPasswordForget} from "../../api/auth";
import {Link, useNavigate} from 'react-router-dom';
import {useSwal} from '../../hooks/swal';
import * as RoutesPath from '../../constants/pages';


const PasswordForgetPage = (props) => {
    const swal = useSwal();

    const [formError, setFormError] = useState(null);
    const [emailField, setEmailField] = useState("");

    const navigate = useNavigate();

    const handleFormSubmit = (e) => {
        e.preventDefault();

        setFormError(null);

        if(!emailField){
            setFormError("Enter votre adresse email");
            window.scrollTo(0, 1);
            return;
        }

        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {})
        swal.showLoading();

        apiPasswordForget(emailField)
            .then((data) => {
                swal.fire({
                    icon: 'success',
                    title: "",
                    text: "Le lien de réinitialisation du mot de passe à été envoyé, merci de consulter votre boite de reception d'email"
                }).then(() => {navigate(RoutesPath.AUTH_SIGN_IN_PAGE.path)});
            })
            .catch((error) => {
                if (error.response){
                    if (error.response.data.message){
                        setFormError(error.response.data.message);
                        swal.close();
                        return;
                    }
                }

                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: 'Impossible de traiter la requête'
                }).then(() => {});

            })
            .then(() => {
                swal.hideLoading();
            })
    };

    useEffect(() => {
        window.scrollTo(0, 1);
    }, []);

    return (
        <>
            <section className="inner-banner">
                <div className="container">
                </div>
            </section>

            <section className="signin py-5">
                <div className="container">
                    <div className="row main-content-agile">
                        <div className="col-lg-6 col-md-9 mx-auto">
                            <div className="sub-main-w3 text-center">
                                <h3>Mot de Passe Oublié</h3>
                                <br />
                                <p className="mt-2 mb-4">Entrer votre email, vous recevrez un mail pour la renitialisation du mot de passe</p>

                                {
                                    formError !== null && (<div className="alert alert-danger" role="alert">{formError}</div>)
                                }

                                <form onSubmit={handleFormSubmit}>
                                    <div className="icon1">
                                        <input onChange={(e) => setEmailField(e.target.value)} value={emailField} placeholder="Email" name="mail" type="email" />
                                    </div>

                                    <label className="anim">
                                        <input type="checkbox" className="checkbox" style={{display:'none'}} />
                                        <span style={{display:'none'}}>Remember Me</span>
                                        <Link to={RoutesPath.AUTH_SIGN_IN_PAGE.path}>Retourner vers la page connexion ?</Link>
                                    </label>

                                    <div className="clearfix"></div>
                                    <input type="submit" value="Confirmer" />
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8 mx-auto">
                            <img src={Banner} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default PasswordForgetPage;
