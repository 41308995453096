import React, {useEffect, useState} from "react";
import ImgAbout4 from '../../../assets/img/about4.png';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import * as RoutesPath from '../../../constants/pages';
import * as apiSubscription from '../../../api/subscription';
import imgIcBox from "../../../assets/img/ic_box.png";
import {useSwal} from '../../../hooks/swal';
import SubscriptionItem from './SubscriptionItem';

const SubscriptionPage = (props) => {

    const [subscriptionList, setSubscriptionList] = useState([]);
    const auth = useSelector((state => state.auth));
    const swal = useSwal();

    useEffect(() => {
        window.scrollTo(0, 1);

        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {})
        swal.showLoading();
        apiSubscription.getUserCustomerSubscriptionsOverview()
            .then((data) => {
                setSubscriptionList(data);
                swal.hideLoading();
                swal.close();
            })
            .catch((err) => {
                swal.hideLoading();
                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: "Une erreur s'est produite"
                }).then(() => {
                    //navigate(-1);
                });
            })

    }, []);

    if (subscriptionList.length === 0){

        return (
            <>
                <section className="inner-banner">
                    <div className="container">
                    </div>
                </section>

                <div className="heading">
                    <h3 className="head text-center">Bienvenue {auth.user.last_name} {auth.user.first_name}</h3>

                    <p className="my-3 head text-center">
                        vous n'avez pas encore effuctué de souscription à une offre Mon Taxi
                    </p>
                </div>

                <section className="about-us py-5">
                    <div className="container py-md-3">
                        <div className="row bottom-grids">
                            <div className="col-lg-6 col-md-7 px-lg-5">
                                <h3 className="my-3">
                                    AL-IKLASS Mon Taxi
                                </h3>
                                <p className="mb-4">
                                    AL-IKLASS Mon Taxi est un service qui permet à toute personne capable et
                                    desireuse d'investir dans le transport, d'obtenir un taxi et d'accordez
                                    la gestion de votre taxi a AL-IKLASS.
                                </p>

                                <p>
                                    <Link className="btn-sm mt-1" to={`${RoutesPath.HOME_PAGE.path}#offre-mon-taxi`}>en savoir plus</Link>
                                    <Link className="ml-2 btn-sm mt-1" to={RoutesPath.MS_NEW_SUBSCRIPTION_PAGE.path}>Souscrire</Link>
                                </p>

                            </div>
                            <div className="col-lg-4 offset-lg-2 col-md-5 col-6 px-lg-5 mt-md-0 mt-4">
                                <img src={imgIcBox} alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </section>
                <br /> <br />
            </>
        );
    }

    return (
        <>
            <section className="inner-banner">
                <div className="container">
                </div>
            </section>


            <section className="latest-news py-5">
                <div className="container py-md-5">

                    <div className="heading">
                        <h3 className="head text-center">Tous les offres Mon Taxi auquelles vous avez souscrit</h3>
                        <p className="my-3 head text-center">
                            <Link className="ml-2 btn-sm mt-1 pp_button" to={RoutesPath.MS_NEW_SUBSCRIPTION_PAGE.path}>Nouvelle Souscription</Link>
                        </p>
                    </div>
                    <div className="row news_grids mx-auto mt-5 pt-3">
                    {subscriptionList.map((item) => {
                        return (
                            <div key={item._id} className="col-lg-4 col-md-6 mt-5">
                                <SubscriptionItem
                                    offerName={item.my_taxi_offer_label}
                                    subscriptionId={item._id}
                                    subscriptionRefCode={item.reference_code}
                                    status={item.status}
                                />
                            </div>
                        )})}
                    </div>

                </div>
            </section>
        </>
    );
}

export default SubscriptionPage;
