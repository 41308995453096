import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './styles/style.css';
import 'jquery/dist/jquery.min';
import 'popper.js/dist/umd/popper.min';
import 'bootstrap/dist/js/bootstrap.min';
import 'smoothscroll-for-websites/SmoothScroll';

import React from 'react';
import ReactDOM from 'react-dom';
import {store, persistor} from './store'
import { Provider } from 'react-redux'
import {BrowserRouter} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';


import App from './App';

const rootElement = document.getElementById("root");
ReactDOM.render(
    (
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </React.StrictMode>
    ),
    rootElement
);
