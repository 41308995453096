import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import imgTaxi from '../../../assets/img/taxi1.png';
import {newSubscriptionPaymentLinkGeneration as apiNewSubscriptionPaymentLinkGeneration} from '../../../api/payment';
import {getTaxiOffers as apiGetTaxiOffers, getAppConfig as apiGetAppConfig} from '../../../api/common';
import {getLoginUser as apiGetLoginUser} from '../../../api/user';
import {useSwal} from '../../../hooks/swal';

const NewSubscriptionPage = (props) => {

    const swal = useSwal();

    const navigate = useNavigate();

    const [taxiOffersData, setTaxiOffersData] = useState([
        // {_id: "", label: "Option 1", offer_version: 1, cost: 1500000, monthly_commission_number: 1500000, local_max_echeance_number: 0, online_max_echeance_number: 3, cost: 1500000},
    ]);

    const [loginUser, setLoginUser] = useState(null); //{state: "", city:"", first_name: "", last_name: "", email: "", phone_number: "", phone_number_prefix: "", address: "", zip_code: "", bank_rib: "", account_confirmed_at: ""},

    const [appConfigData, setAppconfigData] = useState([
        // {online_payment_fees_percent: 3.5},
    ]);

    const [displayConfirmPaymentStep, setDisplayConfirmPaymentStep] = useState(false);

    const [paymentLink, setPaymentLink] = useState("#");

    const [selectedTaxiOfferId, setSelectedTaxiOfferId] = useState("");

    const [selectedEcheanceNumber, setSelectedEcheanceNumber] = useState("");

    const [isPaymentByEcheanceChecked, setIsPaymentByEcheanceChecked] = useState(true);

    const [userAddress, setUserAddress] = useState("");
    const [userZipCode, setUserZipCode] = useState("00000");
    const [imgMyTaxiOption, setImgMyTaxiOption] = useState(imgTaxi);


    const [formError, setFormError] = useState(null);

    const normalizeAmount = (amount) => {
        /*  the amount must be a multiple of 5  */
        amount = parseInt(amount);
        if ((amount % 5) === 0){
            return amount;
        }

        const m5 = Math.floor(amount / 5);

        return (m5 + 1) * 5;
    };

    const handleFormFieldChange = (e) => {

        if (e.target.name === "selected_taxi_offer_id"){
            setSelectedTaxiOfferId(e.target.value);
            setIsPaymentByEcheanceChecked(true);
            setSelectedEcheanceNumber("");

        }
        else if(e.target.name === "selected_echeance_number"){
            setSelectedEcheanceNumber(e.target.value);
        }
        else if(e.target.name === "echeance_payment_checkbox"){
            setIsPaymentByEcheanceChecked(e.target.checked);
            setSelectedEcheanceNumber("");
        }
        else if(e.target.name === "address"){
            setUserAddress(e.target.value);
        }
        else if(e.target.name === "zip_code"){
            setUserZipCode(e.target.value);
        }
        else{

        }
    };

    const handleFormSubmit = (e) => {

        e.preventDefault();

        setFormError(null);

        if(!selectedTaxiOfferId){
            setFormError("Selectionner une Offre");
            window.scrollTo(0, 1);
            return;
        }

        if(isPaymentByEcheanceChecked){
            if(!selectedEcheanceNumber){
                setFormError("Selectionner le nombre d'echeance");
                window.scrollTo(0, 1);
                return;
            }
        }

        if(!userAddress){
            setFormError("Entrer votre adresse");
            window.scrollTo(0, 1);
            return;
        }

        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {});
        swal.showLoading();

        apiNewSubscriptionPaymentLinkGeneration(selectedTaxiOfferId, !selectedEcheanceNumber ? 0 : parseInt(selectedEcheanceNumber), userAddress, userZipCode)
            .then((data) => {
                swal.close();
                swal.hideLoading();

                setPaymentLink(data.payment_url);
                setDisplayConfirmPaymentStep(true);
                window.scrollTo(0, 1);

            })
            .catch((error) => {

                swal.hideLoading();

                if (error.response){
                    if (error.response.data.message){

                        setFormError(error.response.data.message);

                        swal.fire({
                            icon: 'error',
                            title: 'Erreur !!',
                            text: error.response.data.message
                        }).then(() => {});

                        window.scrollTo(0, 1);

                        return;
                    }
                }

                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: 'Impossible de traiter la requête'
                }).then(() => {});

            })
            .then(() => {})
    };

    useEffect(() => {

        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {});
        swal.showLoading();

        Promise.all([apiGetLoginUser(), apiGetTaxiOffers(), apiGetAppConfig()])
        .then(results => {
            setLoginUser(results[0]);
            setTaxiOffersData(results[1]);
            setAppconfigData(results[2]);

            swal.hideLoading();
            swal.close();
        })
        .catch(err => {
            swal.hideLoading();
            swal.fire({
                icon: 'error',
                title: 'Erreur !!',
                text: "Une erreur s'est produite"
            }).then(() => {
                navigate(-1);
            });

        });

        window.scrollTo(0, 1);
    }, []);

    useEffect(() => {

        if (loginUser){
            if (loginUser.address !== ""){
                setUserAddress(loginUser.address);
            }

            if (loginUser.zip_code){
                setUserZipCode(loginUser.zip_code);
            }
        }

    }, [loginUser]);

    useEffect(() => {

        const taxiOffer = taxiOffersData.find(item => (item._id === selectedTaxiOfferId));

        if (taxiOffer){
            setImgMyTaxiOption(selectedTaxiOffer.illustration_img_url);
        }else{
            setImgMyTaxiOption(imgTaxi);
        }

    }, [selectedTaxiOfferId]);


    const selectedTaxiOffer = taxiOffersData.find(item => (item._id === selectedTaxiOfferId));

    const echeanceNumberOptionEl = [];

    if (selectedTaxiOffer?.online_max_echeance_number >= 2){
        let nombreEcheanceMax = selectedTaxiOffer.online_max_echeance_number;
        let nombreEcheanceMin = selectedTaxiOffer.online_min_echeance_number;


        if (nombreEcheanceMin > nombreEcheanceMax){
            nombreEcheanceMin = nombreEcheanceMax
        }

        Array(nombreEcheanceMax).fill(null).forEach((item, index) => {
            if ((index + 1) < (nombreEcheanceMin)){
                return ;
            }
            echeanceNumberOptionEl.push(<option key={1 + index} value={String(1 + index)}>{1 + index}</option>);
        });
    }

    let costByEcheanceToPay = 0;

    if (selectedTaxiOffer && parseInt(selectedEcheanceNumber) > 0){

        costByEcheanceToPay = normalizeAmount(Math.ceil(parseInt(selectedTaxiOffer.cost) / parseInt(selectedEcheanceNumber)));
    }

    let amountToPay = !selectedTaxiOffer ? 0 : selectedTaxiOffer.cost;
    let totalAmountToPayWithFees = 0;
    let onlinePaymentFees = 0;

    if (isPaymentByEcheanceChecked && parseInt(selectedEcheanceNumber) > 0){
        amountToPay = costByEcheanceToPay;
    }

    if (amountToPay > 0){
        totalAmountToPayWithFees = normalizeAmount((amountToPay / (1 - (parseFloat(appConfigData.online_payment_fees_percent) / 100))));
        onlinePaymentFees = totalAmountToPayWithFees - amountToPay;
    }


    if (displayConfirmPaymentStep){

        return (
            <>
                <section className="inner-banner">
                    <div className="container">
                    </div>
                </section>


                <section className="signin py-5">
                    <div className="container">

                        <div className="row main-content-agile">
                            <div className="col-md-7">
                                <div className="sub-main-w3 text-center">
                                    <h3> Souscription Mon Taxi </h3>
                                    <br />

                                    <div id="new_subscription_payment_resume" className="card bg-light mb-3 fon">
                                        {/* <div className="card-header">Header</div> */}
                                        <div className="card-body">
                                            <h5 className="card-title">Récaputilatif</h5>
                                            <div className="card-text text-left">
                                                <div>
                                                    <strong>Offre Choisir :</strong> <span className="float-right">{selectedTaxiOffer?.label}</span>
                                                </div>

                                                <div>
                                                    <strong>Côut de l'offre (FCFA) :</strong> <span className="float-right">{selectedTaxiOffer?.cost}</span>
                                                </div>

                                                <br />

                                                {!!selectedEcheanceNumber && (
                                                    <div>
                                                        <strong>Nombre d'echeance</strong> <span className="float-right"> {selectedEcheanceNumber} </span>
                                                    </div>
                                                )}

                                                <div>
                                                    <strong>{!selectedEcheanceNumber ? "Montant à payer :" : "Montant par écheance :"}</strong> <span className="float-right"> {!selectedEcheanceNumber ? selectedTaxiOffer?.cost : costByEcheanceToPay} </span>
                                                </div>

                                                <div>
                                                    <strong>Frais de paiement en ligne:</strong> <span className="float-right">{onlinePaymentFees}</span>
                                                </div>

                                                <hr />

                                                <div>
                                                    <strong>Total à Payer (FCFA) :</strong> <span className="float-right">{totalAmountToPayWithFees}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <a href={paymentLink} className="pp_button" >Effectuer le Paiement</a>

                                </div>
                            </div>
                            <div className="col-md-5">
                                <img src={imgMyTaxiOption} alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }

    return (
        <>
            <section className="inner-banner">
                <div className="container">
                </div>
            </section>


            <section className="signin py-5">
                <div className="container">
                    <div className="row main-content-agile align-items-center">
                        <div className="col-md-7">
                            <div className="sub-main-w3 text-center">
                                <h3>Souscription Mon Taxi</h3>
                                <p className="mt-2 mb-4">Remplissez le formulaire de souscription</p>

                                {
                                    formError !== null && (<div className="alert alert-danger" role="alert">{formError}</div>)
                                }


                                <form onSubmit={handleFormSubmit}>

                                    <div className="icon1">
                                        <label className="d-block text-left">Selectionner l'offre Mon Taxi</label>
                                        <select className="form-control form-control-md" onChange={handleFormFieldChange} value={selectedTaxiOfferId} placeholder="Offre Taxi" name="selected_taxi_offer_id">
                                            <option value="">Selectionner l'offre</option>
                                            {taxiOffersData.map(item => (<option key={item.label} value={item._id}>{item.label}</option>))}
                                        </select>
                                    </div>

                                    <div className="icon1">
                                        <label className="d-block text-left">Côut Total (FCFA)</label>
                                        <input value={ !selectedTaxiOffer ? "" : "" + String(selectedTaxiOffer.cost)} readOnly placeholder="Coût Total" name="taxi_offer_cost" type="text" />
                                    </div>

                                    {
                                        (echeanceNumberOptionEl.length > 0) && (
                                            <>
                                                <div className="icon1">
                                                    <input disabled checked={isPaymentByEcheanceChecked} type="checkbox" className="form-check-input" id="exampleCheck1" name="echeance_payment_checkbox" />
                                                    <label className="form-check-label" htmlFor="exampleCheck1">Paiement par echeance ?</label>
                                                </div>

                                                <div hidden={!isPaymentByEcheanceChecked} className="icon1">
                                                    <label className="d-block text-left">Selectionner le nombre d'echeance</label>
                                                    <select className="form-control form-control-md" onChange={handleFormFieldChange} value={selectedEcheanceNumber} placeholder="" name="selected_echeance_number" >
                                                        <option value=""></option>
                                                        {echeanceNumberOptionEl}
                                                    </select>
                                                </div>

                                                <div hidden={!isPaymentByEcheanceChecked} className="icon1">
                                                    <label className="d-block text-left">Montant Par Echance (FCFA)</label>
                                                    <input value={costByEcheanceToPay === 0 ? "" : costByEcheanceToPay} readOnly placeholder="Montant Par Echance" name="taxi_offer_payment_by_echeance_cost" type="text" />
                                                </div>

                                                <br />
                                            </>
                                        )
                                    }

                                    <div className="icon1">
                                        <label className="d-block text-left">Frais de paiement en ligne (FCFA)</label>
                                        <input value={onlinePaymentFees === 0 ? "" : onlinePaymentFees} readOnly placeholder="Montant Par Echance" name="taxi_offer_payment_by_echeance_cost" type="text" />
                                    </div>

                                    {
                                        !loginUser?.address && (
                                            <>
                                                <div className="icon1">
                                                    <input required onChange={handleFormFieldChange} value={userAddress} placeholder="Votre Adresse" name="address" type="text" />
                                                </div>
                                            </>
                                        )
                                    }

                                    <div className="icon1">
                                        <input required hidden onChange={handleFormFieldChange} value={userZipCode} placeholder="Zip Code" name="zip_code" type="text" />
                                    </div>

                                    <p hidden={!selectedTaxiOffer}  className="text-center font-weight-bold">TOTAL A PAYER (FCFA) : {totalAmountToPayWithFees}</p>

                                    <input type="submit" value="Continuer" />

                                </form>


                            </div>
                        </div>
                        <div className="col-md-5">
                            <img src={imgMyTaxiOption} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NewSubscriptionPage;
