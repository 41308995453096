import React, {useEffect} from "react";

const ErrorPage = (props) => {

    useEffect(() => {
        window.scrollTo(0, 1);
    }, []);

    return (
        <>
            <section className="inner-banner">
                <div className="container">
                </div>
            </section>


            <section className="faq py-5">
                <div className="container py-md-3">
                    <div className="heading">
                        <h3 className="head text-center">Questions fréquemment posées</h3>
                        <p className="my-3 head text-center">
                        </p>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div className="panel panel-default">
                                    <div className="panel-heading" role="tab" id="headingOne">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Qu'est ce que AL-IKLASS Mon Taxi?
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapseOne" className="panel-collapse collapse in collapse show" role="tabpanel" aria-labelledby="headingOne">
                                        <div className="panel-body">
                                            <p>
                                                AL-IKLASS Mon Taxi est un service qui permet à toute personne capable et
                                                desireuse d'investir dans le transport, d'obtenir un taxi et d'accordez
                                                la gestion de votre taxi a AL-IKLASS.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default">
                                    <div className="panel-heading" role="tab" id="headingTwo">
                                        <h4 className="panel-title">
                                            <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Comment faire pour souscrire à une offre ?
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                        <div className="panel-body">

                                            <p>
                                                Deux option s'offre à vous en se qui concert la souscription.<br /><br />

                                                - Physiquement au sein d'une agence <br/>
                                                - Direction en ligne depuis l'espace membre
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default">
                                    <div className="panel-heading" role="tab" id="headingTwo">
                                        <h4 className="panel-title">
                                            <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseTwo">
                                                Comment faire pour souscrire à une offre en ligne ?
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                        <div className="panel-body">
                                            <p>
                                                <ul className="points pt-2 pl-5">
                                                    <li className="points">1. Creez un compte via le formulaire d'inscription </li>
                                                    <li className="points py-1"> 2. Connectez-vous à votre espace membre </li>
                                                    <li className="points py-1"> 3. Souscrire à une offre depuis la page souscription de l'espace membre</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ErrorPage;
