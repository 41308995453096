import * as React from 'react';
import ImgBp1 from "../../../assets/img/bp1.jpg";
import imgSous from "../../../assets/img/il_sous.png";
import * as RoutesPath from "../../../constants/pages";


const SubscriptionItem = ({offerName, subscriptionRefCode, subscriptionId, status}) => {
    return (
        <>
            <div className="blog-post mb-4 pb-2">
                <div className="bg-light p-4">
                    <h4 className="card-title">Mon taxi {offerName}</h4>
                    <h5 className="">Ref: {subscriptionRefCode}</h5>
                </div>

                <p className="text-center">
                    <img width="60%" src={imgSous} alt="" className="img-fluid" />
                </p>

                <p>
                    <span className="d-inline-block badge badge-info font-weight-bold mt-2 ml-2" style={{fontSize: "0.9em"}}>Etat : {status}</span>
                    <a className="d-inline-block float-right mr-2" href={`${RoutesPath.MS_SUBSCRIPTION_DETAIL_PAGE.path}/${subscriptionId}`}>
                        Voir Plus
                    </a>
                </p>
            </div>

        </>
    );
};

export default SubscriptionItem;
