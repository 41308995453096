import {callApi} from "./_axios";

/* UTILS */

export const getTaxiOffers = async () => {
    const resp = await callApi('/common/taxi-offer', 'get', {});
    return resp.data;
};

export const getAppConfig = async () => {
    const resp = await callApi('/common/app-config', 'get', {});
    return resp.data;
};

export const getCountry= async () => {
    const resp = await callApi('/common/country', 'get', {});
    return resp.data;
};

