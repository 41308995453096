import React from "react";
import {useSelector} from "react-redux";
import {Navigate, Outlet} from "react-router-dom";
import * as RoutesPath from "../constants/pages";

const AuthPagesMdl = (props) => {

    const auth = useSelector(({auth}) => auth);

    // redirect user if already login
    if (auth?.isAuthenticated){
        return <Navigate to={RoutesPath.MS_SUBSCRIPTION_PAGE.path} replace={true} />;
    }

    return (<Outlet />);
}

export default AuthPagesMdl;
