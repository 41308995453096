import React, {useEffect} from "react";
import imgIlTaxi2 from '../../assets/img/il_taxi2.png';
import ImgIlTaxiOption1 from '../../assets/img/il_taxi_option1.png';
import ImgIlTaxiOption2 from '../../assets/img/il_taxi_option2.png';
import ImgIlTaxiOption3 from '../../assets/img/il_taxi_option3.png';
import ImgIlTaxiOption4 from '../../assets/img/il_taxi_option4.png';
import ImgIlTaxiOption5 from '../../assets/img/il_taxi_option5.png';
import ImgIlTaxiOption6 from '../../assets/img/il_taxi_option6.png';
import imgIcOnelinePayment from '../../assets/img/ic_online_payment.jpg';
import imgLocalPayment from '../../assets/img/ic_local_payment.png';
import imgIcTaxiOption1 from '../../assets/img/ic_taxi_option1.png';
import imgIctaxiOption2 from '../../assets/img/ic_taxi_option2.png';
import imgIctaxiOption3 from '../../assets/img/ic_taxi_option3.png';
import imgIlGestionSim from '../../assets/img/il_gestion_sim.png';


const HomePage = (props) => {

    useEffect(() => {
        window.scrollTo(0, 1);
    }, []);

    return (
        <>

            <section className="banner">

                <div className="container">
                    <div className="row banner-grids align-items-center">
                        <div className="col-lg-6 banner-info">
                            <h2 className="">AL-IKLASS</h2>
                            <h3 className="mb-3">Mon Taxi</h3>
                            <p className="mb-4">
                                AL-IKLASS Mon Taxi est un service qui permet à toute personne capable et
                                desireuse d'investir dans le transport, d'obtenir un taxi et d'accordez
                                la gestion de votre taxi a AL-IKLASS.
                            </p>
                            <a href="#offre-mon-taxi">En Savoir Plus</a>
                        </div>
                        <div className="col-lg-6 col-md-9 banner-image">
                            <img src={imgIlTaxi2} width="500px" alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </section>

            <section id="offre-mon-taxi" className="features">
                <div className="container py-md-3">
                    <div className="heading">
                        <h3 className="head text-center">Découvrez nos Offres</h3>
                        <p className="my-3 head text-center" style={{fontSize: "1.3em"}}>
                            AL-IKLASS TRANSPORT dans son service "IKLASS Mon Taxi" a mis en place un système de gestion de Taxis
                            Compteurs de classe confort qui sortent neufs de chez le concessionnaire.
                            <br />
                            <br />
                            Qui permet à partir d'un apport initial, de bénéficier d'une commission mensuel et d'un taxi au terme
                            du contrat en fonction de l'offre.
                            <br />
                            <br />
                            En effet, ce système dispose en son sein 3 options qui sont les suivantes :
                        </p>
                    </div>
                    <div className="tabs">
                        <ul className="nav nav-pills my-md-5 my-3 justify-content-center" id="pills-tab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="option1-tab" data-toggle="pill" href="#option1-tab-content" role="tab" aria-controls="option1-tab-content" aria-selected="true">
                                    <img src={imgIcTaxiOption1} alt="" className="img-fluid"/>
                                    <span className="">Option 1</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="option2-tab" data-toggle="pill" href="#option2-tab-content" role="tab" aria-controls="option2-tab-content" aria-selected="false">
                                    <img src={imgIcTaxiOption1} alt="" className="img-fluid"/>
                                    <span className="">Option 2</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="option3-tab" data-toggle="pill" href="#option3-tab-content" role="tab" aria-controls="option3-tab-content" aria-selected="false">
                                    <img src={imgIcTaxiOption1} alt="" className="img-fluid"/>
                                    <span className="">Option 3</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="option4-tab" data-toggle="pill" href="#option4-tab-content" role="tab" aria-controls="option4-tab-content" aria-selected="false">
                                    <img src={imgIcTaxiOption1} alt="" className="img-fluid"/>
                                    <span className="">Option 4</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="option5-tab" data-toggle="pill" href="#option5-tab-content" role="tab" aria-controls="option5-tab-content" aria-selected="false">
                                    <img src={imgIcTaxiOption1} alt="" className="img-fluid"/>
                                    <span className="">Option 5</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="option6-tab" data-toggle="pill" href="#option6-tab-content" role="tab" aria-controls="option6-tab-content" aria-selected="false">
                                    <img src={imgIcTaxiOption1} alt="" className="img-fluid"/>
                                    <span className="">Option 6</span>
                                </a>
                            </li>
                            
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="option1-tab-content" role="tabpanel" aria-labelledby="option1-tab">
                                <div className="menu-grids">
                                    <div className="row t-in">
                                        <div className="col-lg-6">
                                            <img src={ImgIlTaxiOption1} alt="" className="img-fluid"/>
                                        </div>
                                        <div className="col-lg-6">
                                            <h4 className="mt-2">AL-IKLASS Mon Taxi Option 1</h4>
                                            <p className="my-4">

                                                Lorsque le client fait l'apport de 500.000 FCFA,
                                                l'entreprise gère le taxi et reverse 25.000 FCFA mensuellement
                                                au client sur 48 mois (4 ans) au total.
                                                <br /> Le client reçoit 1.200.000 FCFA durant les 48 mois (4 ans).

                                            </p>
                                            <ul className="points pt-2 pl-5">
                                                <li className="points"> Apport Initial : 500.000 FCFA</li>
                                                <li className="points py-1"> Versement Mensuelle au client : 25.000 FCFA</li>
                                                <li className="points py-1"> Durée du Versement : 48 mois</li>
                                                <li className="points py-1"> Date d'effet : 2 mois plus tard</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="option2-tab-content" role="tabpanel" aria-labelledby="option2-tab">
                                <div className="menu-grids">
                                    <div className="row t-in">
                                        <div className="col-lg-6">
                                            <h4 className="mt-2">AL-IKLASS Mon Taxi Option 2</h4>
                                            <p className="my-4">

                                                Lorsque le client fait l'apport de 1.000.000 FCFA,
                                                l'entreprise gère le taxi et reverse 50.000 FCFA mensuellement
                                                au client sur 48 mois (4 ans) au total.
                                                <br /> Le client reçoit 2.400.000 FCFA durant les 48 mois (4 ans).

                                            </p>
                                            <ul className="points pt-2 pl-5">
                                                <li className="points"> Apport Initial : 1.000.000 FCFA</li>
                                                <li className="points py-1"> Versement Mensuelle au client : 50.000 FCFA</li>
                                                <li className="points py-1"> Durée du Versement : 48 mois</li>
                                                <li className="points py-1"> Date d'effet : 2 mois plus tard</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <img src={ImgIlTaxiOption2} alt="" className="img-fluid"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="option3-tab-content" role="tabpanel" aria-labelledby="option3-tab">
                                <div className="menu-grids">
                                    <div className="row t-in">
                                        <div className="col-lg-6">
                                            <img src={ImgIlTaxiOption3} alt="" className="img-fluid"/>
                                        </div>
                                        <div className="col-lg-6">
                                            <h4 className="mt-2">AL-IKLASS Mon Taxi Option 3</h4>
                                            <p className="my-4">
                                                Lorsque le client fait l'apport de 1.500.000 FCFA,
                                                l'entreprise gère le taxi et reverse 75.000 FCFA mensuellement
                                                au client sur 48 mois (4 ans) au total.
                                                <br /> Le client reçoit 3.600.000 FCFA durant les 48 mois (4 ans).

                                            </p>
                                            <ul className="points pt-2 pl-5">
                                                <li className="points"> Apport Initial : 1.500.000 FCFA</li>
                                                <li className="points py-1"> Versement Mensuelle au client : 75.000 FCFA</li>
                                                <li className="points py-1"> Durée du Versement : 48 mois</li>
                                                <li className="points py-1"> Date d'effet : 2 mois plus tard</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="option4-tab-content" role="tabpanel" aria-labelledby="option4-tab">
                                <div className="menu-grids">
                                    <div className="row t-in">
                                        <div className="col-lg-6">
                                            <h4 className="mt-2">AL-IKLASS Mon Taxi Option 4</h4>
                                            <p className="my-4">

                                                Lorsque le client fait l'apport de 2.000.000 FCFA,
                                                l'entreprise gère le taxi et reverse 100.000 FCFA mensuellement
                                                au client sur 48 mois (4 ans) au total.
                                                <br /> Le client reçoit 4.800.000 FCFA durant les 48 mois (4 ans).

                                            </p>
                                            <ul className="points pt-2 pl-5">
                                                <li className="points"> Apport Initial : 2.000.000 FCFA</li>
                                                <li className="points py-1"> Versement Mensuelle au client : 100.000 FCFA</li>
                                                <li className="points py-1"> Durée du Versement : 48 mois</li>
                                                <li className="points py-1"> Date d'effet : 2 mois plus tard</li>
                                                <li className="points py-1">
                                                    Le taxi est vendu au terme des 48 mois (4 ans) et le client reçois 25% des revenus
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <img src={ImgIlTaxiOption4} alt="" className="img-fluid"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="option5-tab-content" role="tabpanel" aria-labelledby="option5-tab">
                                <div className="menu-grids">
                                    <div className="row t-in">
                                        <div className="col-lg-6">
                                            <img src={ImgIlTaxiOption5} alt="" className="img-fluid"/>
                                        </div>
                                        <div className="col-lg-6">
                                            <h4 className="mt-2">AL-IKLASS Mon Taxi Option 5</h4>
                                            <p className="my-4">
                                                Lorsque le client fait l'apport de 4.000.000 FCFA,
                                                l'entreprise gère le taxi et reverse 200.000 FCFA mensuellement
                                                au client sur 48 mois (4 ans) au total.
                                                <br /> Le client reçoit 9.600.000 FCFA durant les 48 mois (4 ans).

                                            </p>
                                            <ul className="points pt-2 pl-5">
                                                <li className="points"> Apport Initial : 4.000.000 FCFA</li>
                                                <li className="points py-1"> Versement Mensuelle au client : 200.000 FCFA</li>
                                                <li className="points py-1"> Durée du Versement : 48 mois</li>
                                                <li className="points py-1"> Date d'effet : 2 mois plus tard</li>
                                                <li className="points py-1">
                                                    Le taxi est vendu au terme des 48 mois (4 ans) et le client reçois 50% des revenus
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="option6-tab-content" role="tabpanel" aria-labelledby="option6-tab">
                                <div className="menu-grids">
                                    <div className="row t-in">
                                        <div className="col-lg-6">
                                            <h4 className="mt-2">AL-IKLASS Mon Taxi Option 6</h4>
                                            <p className="my-4">

                                                Lorsque le client fait l'apport de 8.000.000 FCFA,
                                                l'entreprise gère le taxi et reverse 400.000 FCFA mensuellement
                                                au client sur 48 mois (4 ans) au total.
                                                <br /> Le client reçoit 19.200.000 FCFA durant les 48 mois (4 ans).

                                            </p>
                                            <ul className="points pt-2 pl-5">
                                                <li className="points"> Apport Initial : 8.000.000 FCFA</li>
                                                <li className="points py-1"> Versement Mensuelle au client : 400.000 FCFA</li>
                                                <li className="points py-1"> Durée du Versement : 48 mois</li>
                                                <li className="points py-1"> Date d'effet : 2 mois plus tard</li>
                                                <li className="points py-1">
                                                    Le taxi est mis à la disposition du client au terme des 48 mois (4 ans) 
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <img src={ImgIlTaxiOption6} alt="" className="img-fluid"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row bottom_grids text-center mt-5 pt-3">
                        <div className="col-lg-4 col-md-6">
                            <img src={imgIlGestionSim} alt="" className="img-fluid"/>
                            <h3 className="my-3">Gestion simplifiée</h3>
                            <p className="mb-4">
                                Gérer simplement vos souscriptions depuis votre backoffice
                            </p>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <img src={imgIcOnelinePayment} alt="" className="img-fluid"/>
                            <h3 className="my-3">Paiement en ligne</h3>
                            <p className="mb-4">
                                Effectuer le paiement de votre souscription directement en ligne
                            </p>
                        </div>
                        <div className="col-lg-4">
                            <img src={imgLocalPayment} width="80px" height="80px" alt="" className="img-fluid" />
                            <h3 className="my-3">Paiement en agence</h3>
                            <p className="mb-4">
                                Rendez-vous au sein de notre agence pour pour souscrire à une offre
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section hidden={true} className="bottom bg-light py-5">
                <div className="container py-md-5 py-3">
                    <div className="heading">
                        <h3 className="head text-center">How to keep your data secure </h3>
                        <p className="my-3 head text-center"> Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nulla mollis dapibus nunc, ut rhoncus
                            turpis sodales quis. Integer sit amet mattis quam.</p>
                    </div>
                    <div className="row bottom-grids mt-5">
                        <div className="col-lg-6 p-5">
                            <img src={imgIlGestionSim} alt="" className="img-fluid" />
                                <h3 className="my-3">Safe and Secure</h3>
                                <p className="mb-4"> Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubiliaetr Curae; Nulla mollis dapibus nunc,
                                    utrea rhoncus turpis sodales quis et ultrices posuere cubilia Curae.</p>
                                <a href="#">Learn More</a>
                        </div>
                        <div className="col-lg-6">
                            <div className="bg-white">
                                <div className="points my-3">
                                    <div className="point">
                                        <p className="points"> Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nulla mollis.</p>
                                        <div className="icon">
                                            <i className="fas fa-check"></i>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="point">
                                        <p className="points"> Rhoncus turpis sodales quis. Integer sit amet.</p>
                                        <div className="icon">
                                            <i className="fas fa-check"></i>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="point">
                                        <p className="points"> Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.</p>
                                        <div className="icon">
                                            <i className="fas fa-check"></i>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="point">
                                        <p className="points"> Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nulla mollis.</p>
                                        <div className="icon">
                                            <i className="fas fa-check"></i>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="point">
                                        <p className="points"> Vestibulum ante ipsum primis in faucibus orci luctus.</p>
                                        <div className="icon">
                                            <i className="fas fa-check"></i>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section hidden={true} className="companies py-5">
                <div className="container py-md-5 py-3">
                    <div className="heading">
                        <h3 className="head text-center">Companies connected with us</h3>
                    </div>
                    <div className="grid mt-5">
                        <div className="marquee">
                            <div className="marquee--inner">
					<span>
					<div className="orb">
						<i className="fab fa-angrycreative"></i>
						<p className="">Faucibus orci luctus etl ultrices posuere ipsum cubilia curae ultrices mollis nunc</p>
					</div>
					<div className="orb red">
						<i className="fab fa-apple-pay"></i>
						<p className="">Faucibus orci luctus etl ultrices posuere ipsum cubilia curae ultrices mollis nunc</p>
					</div>
					<div className="orb green">
						<i className="fab fa-apper"></i>
						<p className="">Faucibus orci luctus etl ultrices posuere ipsum cubilia curae ultrices mollis nunc</p>
					</div>
					<div className="orb blue">
						<i className="fab fa-cpanel"></i>
						<p className="">Faucibus orci luctus etl ultrices posuere ipsum cubilia curae ultrices mollis nunc</p>
					</div>
					</span>
                                <span>
					<div className="orb pink">
						<i className="fab fa-digg"></i>
						<p className="">Faucibus orci luctus etl ultrices posuere ipsum cubilia curae ultrices mollis nunc</p>
					</div>
					<div className="orb orange">
						<i className="fab fa-ember"></i>
						<p className="">Faucibus orci luctus etl ultrices posuere ipsum cubilia curae ultrices mollis nunc</p>
					</div>
					<div className="orb yellow">
						<i className="fab fa-hooli"></i>
						<p className="">Faucibus orci luctus etl ultrices posuere ipsum cubilia curae ultrices mollis nunc</p>
					</div>
					<div className="orb purple">
						<i className="fab fa-hips"></i>
						<p className="">Faucibus orci luctus etl ultrices posuere ipsum cubilia curae ultrices mollis nunc</p>
					</div>
					</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="subscribe py-5">
                <div className="container py-md-5">
                    <div className="heading">
                        <h3 className="head text-center">Newsletter</h3>
                        <p className="my-3 head text-center">
                            souhaitez vous être informé des dernières information de AL-IKLASS Mon Taxi ?
                        </p>
                    </div>
                    <form action="#" method="post" className="mt-5">
                        <div className="d-flex mb-2">
                            <input type="email" name="Email" placeholder="Entrer votre email..." required />
                            <input type="submit" value="M'Inscrire" />
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
}

export default HomePage;
