import React, {useEffect, useState} from "react";
import * as apiSubscription from "../../../api/subscription";
import {useNavigate, useParams} from 'react-router-dom';
import {useSwal} from "../../../hooks/swal";
import {normalizeAmount} from '../../../libs/utils';
import {getAppConfig as apiGetAppConfig} from "../../../api/common";
import {echeancePaymentLinkGeneration as apiEcheancePaymentLinkGeneration} from "../../../api/payment";
import imgTaxi from '../../../assets/img/taxi1.png';

const SubscriptionEcheancePaymentPage = (props) => {

    const swal = useSwal();
    const navigate = useNavigate();

    const params = useParams();

    const [subscriptionData, setSubscriptionData] = useState(null);
    const [displayConfirmPaymentStep, setDisplayConfirmPaymentStep] = useState(null);
    const [paymentLink, setPaymentLink] = useState(null);
    const [appConfigData, setAppconfigData] = useState([
        // {online_payment_fees_percent: 3.5},
    ]);

    const handleNewEcheancePayment = () => {
        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {})
        swal.showLoading();

        apiEcheancePaymentLinkGeneration(subscriptionData._id)
            .then((data) => {
                swal.close();
                swal.hideLoading();

                setPaymentLink(data.payment_url);
                setDisplayConfirmPaymentStep(true);
                window.scrollTo(0, 1);

            })
            .catch((error) => {

                swal.hideLoading();

                if (error.response){
                    if (error.response.data.message){

                        swal.fire({
                            icon: 'error',
                            title: 'Erreur !!',
                            text: error.response.data.message
                        }).then(() => {});

                        window.scrollTo(0, 1);

                        return;
                    }
                }

                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: 'Impossible de traiter la requête'
                }).then(() => {});

            })
            .then(() => {})
    };

    useEffect(() => {

        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {});
        swal.showLoading();

        Promise.all([apiSubscription.getDetailedUserCustomerSubscription(params.id), apiGetAppConfig()])
            .then(results => {
                setSubscriptionData(results[0]);
                setAppconfigData(results[1]);

                const subscriptionD = results[0];

                swal.hideLoading();

                if (!!subscriptionD.offer_cost_payment_completed_at){

                    swal.fire({
                        icon: 'success',
                        title: '',
                        text: "Merci le paiement de cette souscription à déja été soldé"
                    }).then(() => {
                        navigate(-1, {replace: true});
                    });

                }
                else if (!subscriptionD.echeance_payment_status.is_actived){

                    swal.fire({
                        icon: 'error',
                        title: 'Erreur !!',
                        text: "Cette souscription de dispose de d'option de paiement par echeance"
                    }).then(() => {
                        navigate(-1, {replace: true});
                    });
                }
                else {
                    swal.close();
                }
            })
            .catch(err => {
                swal.hideLoading();
                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: "Une erreur s'est produite"
                }).then(() => {
                    navigate(-1, {replace: true});
                });
            });

        window.scrollTo(0, 1);

    }, []);

    if ((!subscriptionData) || (!subscriptionData.echeance_payment_status.is_actived) || (!!subscriptionData.offer_cost_payment_completed_at)){
        return (
            <>
                <section className="inner-banner">
                    <div className="container">
                    </div>
                </section>
            </>
        );
    }

    const totalAmountToPayWithFees = normalizeAmount((subscriptionData.echeance_payment_status.amount_by_echeance_to_pay / (1 - (parseFloat(appConfigData.online_payment_fees_percent) / 100))));
    const onlinePaymentFees = totalAmountToPayWithFees - subscriptionData.echeance_payment_status.amount_by_echeance_to_pay;

    if (displayConfirmPaymentStep){

        return (
            <>
                <section className="inner-banner">
                    <div className="container">
                    </div>
                </section>

                <section className="signin py-5">
                    <div className="container">
                        <div className="row main-content-agile align-items-center">
                            <div className="col-md-7">
                                <div className="sub-main-w3 text-center">
                                    <h3>Paiement d'échéance</h3>

                                    <br />

                                    <div id="new_subscription_payment_resume" className="card bg-light mb-3 fon py-4">
                                        {/* <div className="card-header">Header</div> */}
                                        <div className="card-body">
                                            <h5 className="card-title">Récaputilatif</h5>

                                            <div className="card-text text-left">

                                                <div>
                                                    <strong>Type d'Offre :</strong> <span className="float-right">{subscriptionData.my_taxi_offer_id.label}</span>
                                                </div>

                                                <div>
                                                    <strong>Ref :</strong> <span className="float-right">{subscriptionData.reference_code.slice(0, 7)}...</span>
                                                </div>

                                                <br />

                                                <div>
                                                    <strong>Montant par écheance (FCFA) :</strong> <span className="float-right">{subscriptionData.echeance_payment_status.amount_by_echeance_to_pay}</span>
                                                </div>

                                                <div>
                                                    <strong>Frais de paiement en ligne:</strong> <span className="float-right">{onlinePaymentFees}</span>
                                                </div>

                                                <hr />

                                                <div>
                                                    <strong>Total à Payer (FCFA) :</strong> <span className="float-right">{totalAmountToPayWithFees}</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <a href={paymentLink} className="pp_button" >Effectuer le Paiement</a>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <img src={imgTaxi} alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }

    return (
        <>
            <section className="inner-banner">
                <div className="container">
                </div>
            </section>


            <section className="latest-news">
                <div className="container py-md-5">

                    <div className="heading">
                        <h4 className="head text-center">Les informations sur le paiment par écheance  Mon Taxi auquelle vous avez souscrit</h4>
                        <p className="text-center">
                        </p>
                    </div>

                    <div className="contact-form mt-5">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-10 mx-auto">
                                <form id="contactform" action="#">
                                    <div className="form-group">
                                        <label>Type d'Offre</label>
                                        <input defaultValue={`${subscriptionData.my_taxi_offer_id.label}`} readOnly type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Code de Souscription</label>
                                        <input defaultValue={subscriptionData.reference_code} readOnly className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Nombre total d'Echeance</label>
                                        <input defaultValue={subscriptionData.echeance_payment_status.number_echeance_chosen} readOnly type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Montant Par Echeance (FCFA)</label>
                                        <input defaultValue={subscriptionData.echeance_payment_status.amount_by_echeance_to_pay} readOnly type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Nombre total d'Echeance Soldé</label>
                                        <input defaultValue={subscriptionData.echeance_payment_status.paid_echeance_count} readOnly type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Nombre total d'Echeance Restant</label>
                                        <input defaultValue={subscriptionData.echeance_payment_status.number_echeance_chosen - subscriptionData.echeance_payment_status.paid_echeance_count} readOnly type="text" className="form-control" />
                                    </div>
                                </form>
                                <p className="text-center">
                                    <button onClick={handleNewEcheancePayment} type="button" className="ml-2 btn-sm mt-1 btn pp_button">Nouvelle Paiement d'écheance</button>
                                </p>
                            </div>
                            <div className="col-md-5">
                                <img src={imgTaxi} alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SubscriptionEcheancePaymentPage;
