import React from "react";
import {Link, NavLink, useLocation, useNavigate} from 'react-router-dom';
import * as RoutesPath from '../../constants/pages';
import {useDispatch, useSelector} from "react-redux";
import {signOut} from '../../features/auth';
import imgLogo from '../../assets/img/logo_icon_only.png';
import imgTaxi from '../../assets/img/taxi-solid.svg';

const NavBar = (props) => {
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state => state.auth));


    const handleActiveLinkStyle = ({isActive}) => {
        if (isActive) {
            return {
                color: '#30c39e'
            };
        }
    };

    const handleSignout = (e) => {
        e.preventDefault();

        if (auth.isAuthenticated) {
            dispatch(signOut());
            navigate(RoutesPath.AUTH_SIGN_IN_PAGE.path, {replace: true})
        }
    };

    return (
        <>
            <header className="bg-white py-1">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <h1>
                            <Link className="navbar-brand" to="/">
                                <img style={{marginBottom: 5}} src={imgTaxi} width={30} height={30} />
                                <span className="app_title">AL-IKLASS Mon Taxi</span>
                            </Link>
                        </h1>

                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-lg-4 mr-auto">
                                <li className="nav-item">
                                    <NavLink to={RoutesPath.HOME_PAGE.path} className="nav-link" style={handleActiveLinkStyle}>
                                        Accueil <span className="sr-only">(current)</span>
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink to={RoutesPath.FAQ_PAGE.path} className="nav-link" style={handleActiveLinkStyle}>Faq</NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink to={RoutesPath.CONTACT_PAGE.path} className="nav-link" style={handleActiveLinkStyle}>Contact</NavLink>
                                </li>
                            </ul>
                            <div className="header-right">
                                {
                                    ! auth.isAuthenticated ? (
                                        <>
                                            <Link to={RoutesPath.AUTH_SIGN_IN_PAGE.path} className="signin mr-4"> Connexion <i className="fas fa-sign-in-alt"> </i></Link>
                                            <Link to={RoutesPath.AUTH_SIGN_UP_PAGE.path} className="contact">Inscription</Link>
                                        </>
                                    ) : (
                                        <>
                                            <ul className="navbar-nav ml-lg-4 mr-auto">
                                                <li className="nav-item dropdown">
                                                    <a
                                                        href="#"
                                                        className="nav-link dropdown-toggle"
                                                        id="navbarDropdown1"
                                                        role="button"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        style={[
                                                            RoutesPath.MS_SUBSCRIPTION_PAGE.path,
                                                            RoutesPath.MS_NEW_SUBSCRIPTION_PAGE.path,
                                                            RoutesPath.MS_SUBSCRIPTION_DETAIL_PAGE.path,
                                                            RoutesPath.MS_PROFILE_PAGE.path,
                                                        ].includes(pathname) ? handleActiveLinkStyle({isActive: true}) : null}
                                                    >
                                                        <i style={{fontSize: "1.3em"}} className="fa fa-user-circle"></i> Espace Client
                                                    </a>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <Link to={RoutesPath.MS_SUBSCRIPTION_PAGE.path} className="dropdown-item">Souscriptions</Link>
                                                        <Link to={RoutesPath.MS_PROFILE_PAGE.path} className="dropdown-item">Profile</Link>
                                                        <a href="#" onClick={handleSignout} className="dropdown-item">Deconnexion</a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
}

export default NavBar;
