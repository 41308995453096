import React, {useEffect, useState} from "react";
import ImgIlp from '../../../assets/img/ic_document.png';
import * as RoutesPath from '../../../constants/pages';
import {useSwal} from "../../../hooks/swal";
import {useNavigate, useParams} from "react-router-dom";
import * as PpUtils from '../../../libs/utils';
import * as apiSubscription from "../../../api/subscription";
const SubscriptionDetailPage = (props) => {

    const swal = useSwal();
    const navigate = useNavigate();
    const params = useParams();
    const [subscriptionData, setSubscriptionData] = useState(null);

    const contractUrl = `${process.env.REACT_APP_BACKEND_URL}/subscription-contrat/${params.id}`;
    const echeancePaymentLink = `${RoutesPath.MS_SUBSCRIPTION_ECHEANCE_PAYMENT_PAGE.path}/${params.id}`;

    useEffect(() => {

        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {});
        swal.showLoading();

        Promise.all([apiSubscription.getDetailedUserCustomerSubscription(params.id)])
            .then(results => {
                setSubscriptionData(results[0]);
                swal.hideLoading();
                swal.close();
            })
            .catch(err => {
                swal.hideLoading();
                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: "Une erreur s'est produite"
                }).then(() => {
                    navigate(-1);
                });
            });


        window.scrollTo(0, 1);
    }, []);

    if (!subscriptionData){
        return (
            <>
                <section className="inner-banner">
                    <div className="container">
                    </div>
                </section>
            </>
        );
    }

    return (
        <>
            <section className="banner">
                <div className="container">
                    <div className="row banner-grids align-items-center">
                        <div className="col-md-9 banner-info">
                            <h2 className="">AL-IKLASS Mon Taxi</h2>
                            <h3 className="mb-3"></h3>
                            <p className="mb-4">
                                Ci-dessous les informations retalives à votre souscription
                            </p>
                            <a style={{display: "none"}} href={contractUrl}>Telecharger le contrat</a>
                        </div>
                        <div className="col-md-3 banner-image">
                            <img width="100px" src={ImgIlp} alt="" className="img-fluid"/>
                        </div>
                    </div>

                    <h3 className="mb-3 display-4" style={{fontSize: "2em"}}>Généralité</h3>

                    <div className="card bg-light mb-3 fon py-4">
                        {/* <div className="card-header">Header</div> */}
                        <div className="card-body">
                            <div className="card-text text-left">

                                <dl className="row">
                                    <dt className="col-sm-4">Réference</dt>
                                    <dd className="col-sm-8">{subscriptionData.reference_code}</dd>

                                    <dt className="col-sm-4">Type d'Offre</dt>
                                    <dd className="col-sm-8">
                                        {subscriptionData.my_taxi_offer_id.label}
                                    </dd>

                                    <dt className="col-sm-4">Coût de l'offre (FCFA)</dt>
                                    <dd className="col-sm-8">
                                        {subscriptionData.subscribed_taxi_offer.cost}
                                    </dd>

                                    <dt className="col-sm-4">Date de la Souscription</dt>
                                    <dd className="col-sm-8">
                                        {PpUtils.formatDate(subscriptionData.subscribed_at)}
                                    </dd>

                                    <dt className="col-sm-4">Etat</dt>
                                    <dd className="col-sm-8">
                                        <span className="badge badge-info">{subscriptionData.status}</span>
                                    </dd>

                                    <dt className="col-sm-4">Paiement Par Echeance ? </dt>
                                    <dd className="col-sm-8">
                                        <span className="badge badge-info">{subscriptionData.echeance_payment_status.is_actived ? "OUI" : "NON"}</span>
                                    </dd>

                                    <dt className="col-sm-4">Paiement Soldé ?</dt>
                                    <dd className="col-sm-8">
                                        <span className="badge badge-info">{(!subscriptionData.offer_cost_payment_completed_at) ? "Non" : `Oui le ${PpUtils.formatDate(subscriptionData.offer_cost_payment_completed_at)}`}</span>
                                    </dd>

                                    <dt className="col-sm-4">Commission Mensuelle (FCFA)</dt>
                                    <dd className="col-sm-8">
                                        {subscriptionData.subscribed_taxi_offer.monthly_commission_amount}
                                    </dd>

                                    <dt className="col-sm-4">Nombre de Commisions Mensuelle</dt>
                                    <dd className="col-sm-8">
                                        {subscriptionData.subscribed_taxi_offer.monthly_commission_number}
                                    </dd>

                                    {(subscriptionData.offer_cost_payment_completed_at) && (
                                        <>
                                            <dt className="col-sm-4">Periode Debut Commission</dt>
                                            <dd className="col-sm-8">
                                                {PpUtils.formatDatePeriode(subscriptionData.commission_payment_start_date)}
                                            </dd>

                                            <dt className="col-sm-4">Periode Fin Commission</dt>
                                            <dd className="col-sm-8">
                                                {PpUtils.formatDatePeriode(subscriptionData.commission_payment_end_date)}
                                            </dd>
                                        </>
                                    )}

                                    <dt className="col-sm-4">Contrat</dt>
                                    <dd className="col-sm-8">
                                        <a 
                                            className="btn btn-secondary btn-sm ml-1" 
                                            target="_blank"
                                            href={`${process.env.REACT_APP_BACKEND_URL}/reports/contract/${subscriptionData._id}`}
                                        >
                                            Voir
                                        </a>

                                        <a 
                                            className="btn btn-secondary btn-sm ml-1" 
                                            target="_blank"
                                            href={`${process.env.REACT_APP_BACKEND_URL}/reports/contract/${subscriptionData._id}/_download`}
                                        >
                                            Télécharger
                                        </a>
                                    </dd>

                                </dl>

                            </div>
                        </div>
                    </div>

                    {subscriptionData.echeance_payment_status.is_actived && (
                        <>
                            <h3 className="mt-5 display-4" style={{fontSize: "2em"}}>Paiement par echeance</h3>

                            <div className="card bg-light fon px-4 pt-4">
                                {/* <div className="card-header">Header</div> */}
                                <div className="card-body">
                                    <div className="card-text text-left">
                                        <p style={{color: "black"}}>
                                            Nombre d'echeance: {subscriptionData.echeance_payment_status.number_echeance_chosen} <br />
                                            Nombre d'echeance OK: {subscriptionData.echeance_payment_status.paid_echeance_count} <br />
                                            Montant par écheance (FCFA): {subscriptionData.echeance_payment_status.amount_by_echeance_to_pay} <br />
                                        </p>

                                        <p hidden={!(subscriptionData.echeance_payment_status.paid_echeance_count < subscriptionData.echeance_payment_status.number_echeance_chosen)}>
                                            <span className="font-italic">Pour effectuer un nouveau d'écheance <a href={echeancePaymentLink}>cliquez ici</a></span>
                                            <a href={echeancePaymentLink} className="btn btn-secondary btn-sm text-white ml-2"> Paiement</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {/*Payment*/}
                    <div>
                        <h3 className="mt-5 mb-3 display-4" style={{fontSize: "2em"}}>Historiques des paiements</h3>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">N° Reçu</th>
                                    <th scope="col">Montant (FCFA)</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Type de Paiement</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subscriptionData.subscription_payments.length > 0 ?
                                    (
                                        <>
                                            {subscriptionData.subscription_payments.map((item) => {
                                                return (
                                                    <tr key={item._id}>
                                                        <th scope="row">{item.receipt_number}</th>
                                                        <td>{item.offer_subscription_amount + item.online_payment_fees_amount}</td>
                                                        <td>{PpUtils.formatDate(item.added_at)}</td>
                                                        <td>{item.is_online_payment ? "en ligne" : "en agence"}</td>
                                                    </tr>
                                                )
                                            })}
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <tr>
                                                <th scope="row" colSpan={4} className="text-center">aucune information disponible</th>
                                            </tr>
                                        </>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    {/*Commission*/}
                    <div>
                        <h3 className="mt-5 mb-3 display-4" style={{fontSize: "2em"}}>Commissions</h3>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Periode</th>
                                    <th scope="col">Mode</th>
                                    <th scope="col">Date de Paiement</th>
                                </tr>
                            </thead>
                            <tbody>
                            {subscriptionData.commission_payment_status.length > 0 ?
                                (
                                    <>
                                        {subscriptionData.commission_payment_status.map((itemCom) => {
                                            return (
                                                <tr key={itemCom._id}>
                                                    <th>
                                                        <a 
                                                            className="btn btn-secondary btn-sm ml-1" 
                                                            target="_blank"
                                                            href={`${process.env.REACT_APP_BACKEND_URL}/reports/commission-payment/${itemCom._id}`}
                                                        >
                                                            Facture
                                                        </a>

                                                        <a 
                                                            className="btn btn-secondary btn-sm ml-1" 
                                                            target="_blank"
                                                            href={`${process.env.REACT_APP_BACKEND_URL}/reports/commission-payment/${itemCom._id}/_download`}
                                                        >
                                                            Télécharger
                                                        </a>
                                                    </th>
                                                    <th scope="row">{PpUtils.formatDatePeriode(itemCom.periode)}</th>
                                                    <td>{itemCom.payment_type}</td>
                                                    <td>{PpUtils.formatDate(itemCom.added_at)}</td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                )
                                :
                                (
                                    <>
                                        <tr>
                                            <th scope="row" colSpan={4} className="text-center">aucune information disponible</th>
                                        </tr>
                                    </>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

            <br />
            <br />
        </>
    );
}

export default SubscriptionDetailPage;
