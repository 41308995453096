import React, {useEffect, useState} from "react";
import Banner from '../../assets/img/banner.png';
import {checkPasswordResetToken as apiCheckPasswordResetToken, resetPassword as apiResetPassword} from "../../api/auth";
import {useSearchParams, useNavigate, Navigate} from 'react-router-dom';
import {useSwal} from '../../hooks/swal';
import * as RoutesPath from '../../constants/pages';


const PasswordResetPage = (props) => {
    const swal = useSwal();
    const [formError, setFormError] = useState(null);
    const [passwordField, setPasswordField] = useState("");
    const [passwordField2, setPasswordField2] = useState("");
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isInvalidToken, setIsInvalidToken] = useState(false);
    const [isTokenChecked, setIsTokenChecked] = useState(false);
    const passwordResetToken = searchParams.get('token');


    const handleFormSubmit = (e) => {
        e.preventDefault();

        setFormError(null);

        if(!passwordField || ! passwordField2){
            setFormError("Enter le mot de paase");
            window.scrollTo(0, 1);
            return;
        }

        if(passwordField.length < 8){
            setFormError("Le mot de passe doit avoit au moin 8 caractères");
            window.scrollTo(0, 1);
            return;
        }

        if(passwordField !== passwordField2){
            setFormError("Mode passe non identique");
            window.scrollTo(0, 1);
            return;
        }

        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {})
        swal.showLoading();

        apiResetPassword(passwordResetToken, passwordField)
            .then((data) => {
                swal.fire({
                    icon: 'success',
                    title: '',
                    text: "Le mot de passe à été réinitialisé avec succès."
                }).then(() => {
                    navigate(RoutesPath.AUTH_SIGN_IN_PAGE.path, {replace: true});
                });
            })
            .catch((error) => {
                if (error.response){
                    console.log(error.response);
                    if (error.response.data.message){
                        setFormError(error.response.data.message);
                        swal.close()
                        return;
                    }
                }

                swal.fire({
                    icon: 'error',
                    title: 'Erreur !!',
                    text: 'Impossible de traiter la requête'
                }).then(() => {});

            })
            .then(() => {
                setPasswordField("");
                setPasswordField2("");
                swal.hideLoading();
            })
    };

    useEffect(() => {
        window.scrollTo(0, 1);

        swal.fire({text: "Merci de Patienter", allowOutsideClick: false}).then(() => {})
        swal.showLoading();

        // check if token is valid or active
        apiCheckPasswordResetToken(passwordResetToken)
            .then((data) => {
            })
            .catch((error) => {
                setIsInvalidToken(true);
            })
            .then(() => {
                setIsTokenChecked(true);
                swal.hideLoading();
                swal.close();
            })
    }, []);

    if (!passwordResetToken){
        //
        setIsInvalidToken(true);
    }

    if(!isTokenChecked){

        return (
            <></>
        );
    }

    if (isInvalidToken){
        return (
            <>
                <section className="inner-banner">
                    <div className="container">
                    </div>
                </section>

                <section className="about-us py-5">
                    <div className="container py-md-3">
                        <div className="heading mb-5">
                            <h3 className="head text-center">
                            </h3>

                            <p className="my-3 head text-center text-m">
                                le lien à expirer ou est invalide
                            </p>

                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </section>
            </>
        );
    }

    return (
        <>
            <section className="inner-banner">
                <div className="container">
                </div>
            </section>

            <section className="signin py-5">
                <div className="container">
                    <div className="row main-content-agile">
                        <div className="col-lg-6 col-md-9 mx-auto">
                            <div className="sub-main-w3 text-center">
                                <h3>Réinitialisation du Mot de Passe</h3>

                                <p className="mt-2 mb-4">Entrer votre votre nouveau mot de passe</p>

                                {
                                    formError !== null && (<div className="alert alert-danger" role="alert">{formError}</div>)
                                }

                                <form onSubmit={handleFormSubmit}>
                                    <div className="icon2">
                                        <input onChange={(e) => setPasswordField(e.target.value)} value={passwordField} placeholder="Mot de Passe" name="Password" type="password"  />
                                    </div>
                                    <div className="icon2">
                                        <input onChange={(e) => setPasswordField2(e.target.value)} value={passwordField2} placeholder="Confirmer le Mot de Passe" name="Password2" type="password"  />
                                    </div>
                                    <div className="clearfix"></div>
                                    <input type="submit" value="Confirmer" />
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8 mx-auto">
                            <img src={Banner} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default PasswordResetPage;
